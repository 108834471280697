import { useState, useEffect } from "react";
import { Col, Row, Alert } from "react-bootstrap";
import { Container} from "react-bootstrap";
import React from 'react'
import emailjs from '@emailjs/browser';

export const Newsletter = () => {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    // Your EmailJS service ID, template ID, and Public Key
    const serviceId = 'service_mtc6yfw';
    const templateId = 'template_ixuum2k';
    const publicKey = 'LBHEJ9RwmjtXh-tj2';

    // Create a new object that contains dynamic template params
    const templateParams = {
      from_name: name,
      from_email: email,
      to_name: 'Emanuel',
      message: message,
    };

    // Send the email using EmailJS
    emailjs.send(serviceId, templateId, templateParams, publicKey)
      .then((response) => {
        console.log('Email sent successfully!', response);
        setName('');
        setEmail('');
        setMessage('');
      })
      .catch((error) => {
        console.error('Error sending email:', error);
      });
  }
  return (
    <section className="newsletter" id="connect">
      <Container>
        <Row className="justify-content-center">
          <Col lg={10}>
            <div className="newsletter-bx wow slideInUp">
              <h3>Subscribe to our Newsletter</h3>
              <h4>Never miss latest updates</h4>
              <form onSubmit={handleSubmit} className='emailForm'>
                <input
                  type="email"
                  placeholder="Your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <button type="submit">Send Email</button>
              </form>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}